
import { IProduct } from '@/interfaces/product';
import Base from '@/mixins/Base.vue';
import { defineComponent } from 'vue';
import Countries from '@/components/Countries.vue';
import Categories from '@/components/Categories.vue';

export default defineComponent({
    components: { Categories, Countries },
    mixins: [Base],
    mounted() {
        this.getItems();
    },
    data() {
        return {
            selected: [],

            items: {
                data: [] as Array<IProduct>,
                meta: {
                    total: 0,
                },
            },
            params: {
                page: 1,
                filter: '',
                type: 'product',
                sortBy: 'created_at',
                platform: undefined as any,
                country: undefined as any,
                category: undefined as any,
                orderBy: 'desc',
                has_warning: false,
                has_spoq_id: false,
                has_forms_id: false,
                has_forms_report_type: false,
                has_duplicates_code: false,
                active: true,
            },
        };
    },
    methods: {
        bulk(action, params) {
            console.log(this.selected.length);
            this.post('products/bulk', {
                params,
                ids: this.selected.map((s) => s.id),
                action,
            })
                .then(() => {
                    this.selected = [];
                    this.getItems();
                });
        },
        getItems(page = 1) {
            this.params.page = page;
            this.get('products', {
                params: {
                    ...this.params,
                    platform_id: this.params.platform?.id,
                    country_id: this.params.country?.id,
                    category_id: this.params.category?.id,
                },
            })
                .then(({ data }) => {
                    this.items.data = data.data;
                    this.items.meta = data.meta;
                });
        },
    },
});
